<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="lease-content">
      <div class="left">
        <el-tabs v-model="activeName" tab-position="left" style="min-height: 200px;">
          <el-tab-pane v-for="item in list" :key="item.docKey" :label="item.docKeyName" :name="item.docKey"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="right">
        <div class="title">{{ title }}</div>
        <!--        <el-upload class="upload-demo" action="" accept="doc,docx" :auto-upload="false" :show-file-list="false" :on-change="handlerArtSuccess">-->
        <!--          <el-button icon="el-icon-upload2" size="small" type="primary">导入</el-button>-->
        <!--          &lt;!&ndash; <div slot="tip" class="el-upload__tip">只能上传.docx文件</div> &ndash;&gt;-->
        <!--        </el-upload>-->
        <Editor ref="editor" :content.sync="content"></Editor>
        <div style="margin-top:20px;">
          <el-button v-checkbtn="isShowBtn(AUTH_BTN.operation_lease_submit)" type="primary" size="small" @click="save">保存</el-button>
          <el-button type="" size="small" @click="cancel">取消</el-button>
        </div>
      </div>
    </div>
    <div style="height:40px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getCategoryListAPI, getDetailAPI, handleEditAPI } from './api'
import * as mammoth from 'mammoth'
import Editor from '@/components/editor/index.vue'
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { Editor },
  data() {
    return {
      activeName: '',
      content: '',
      list: []
    }
  },
  computed: {
    title() {
      let title = ''
      title = this.list.find(item => item.docKey == this.activeName)?.docKeyName || ''
      return title
    }
  },
  watch: {
    activeName: {
      handler(newV, oldV) {
        if (newV != 0) {
          this.getDetail()
        }
      }
    }
  },
  mounted() {
    this.getCategoryList()
    this.$nextTick(() => {
      getCategoryListAPI().then(res => {
        this.activeName = res[0]['docKey'] || ''
      })
    })
  },

  methods: {
    // 分类
    async getCategoryList() {
      this.list = await getCategoryListAPI()
    },
    // 详情
    async getDetail() {
      const res = await getDetailAPI(this.activeName)
      this.content = res.content
      this.$refs.editor.init()
    },
    save() {
      let data = { docKey: this.activeName, content: this.content }
      handleEditAPI(data).then(() => {
        this.$message.success('保存成功')
        this.getDetail()
      })
    },
    cancel() {
      this.getDetail()
      // 初始化编辑器
      this.$refs.editor.init()
    },
    handlerArtSuccess(file) {
      let reader = new FileReader()
      reader.readAsArrayBuffer(file.raw)
      reader.onload = e => {
        const data = e.target.result
        mammoth.convertToHtml({ arrayBuffer: data }).then(res => {
          this.displayResult(res.value)
        })
      }
    },
    displayResult(html) {
      let newHtml = html
        .replace('<h1>', '<h1 style="text-align:center;">')
        .replace('<h2>', '<h2 style="text-align:center;">')
        .replace('<img', '<img style="width:20%;"')
        .replace(/<table>/g, '<table style="border-collapse:collapse;">')
        .replace(/<tr>/g, '<tr style="height:30px;">')
        .replace(/<td>/g, '<td style="border:1px solid #ccc">')
        .replace(/<p>/g, '<p style="text-indent:2em;">')
      this.content = newHtml
      this.$refs.editor.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .lease-content {
    display: flex;
    .left {
      width: 150px;
      .el-tabs /deep/ {
        .el-tabs__nav-wrap.is-left::after {
          width: 0;
        }
      }
    }
    .right {
      flex: 1;
      .title {
        margin-bottom: 20px;
        margin-top: 8px;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
</style>
